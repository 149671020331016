.landing-page {
  p, li {
    font-size: 23px;
    line-height: 40px;
    letter-spacing: -0.015em;
  }

  h3 {
    font-weight: bold;
    font-size: 45px;
    line-height: 66px;
    letter-spacing: -0.015em;
  }

  .landing-hero {
    h1 {
      font-weight: bold;
      font-size: 58px;
      line-height: 69px;
      letter-spacing: -0.015em;
    }

    &.write-your-own {
      padding-top: 8%;
      padding-bottom: 270px;
      color: white;
      background-size: cover;
      background: black url('../images/landing/living-legacy/woman-writing.jpeg') no-repeat center;

      .message {
        padding: 3rem;
        background: rgba(0,0,0,0.4);
      }
    }
  }

  .landing-quote {
    font-size: 45px;
    line-height: 65px;
    text-align: center;
    letter-spacing: -0.015em;
    padding-top: 116px;
    padding-bottom: 116px;

    .text {
      margin: auto;
    }
  }

  .call-to-action {
    padding-top: 82px;
    padding-bottom: 82px;

    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 50px;
      text-align: center;
      letter-spacing: -0.015em;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .btn.btn-lg {
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    padding: 10px 40px;
  }
}
