$app-navy: rgb(64, 64, 64);
$app-pink: #B78AA7;
$app-light-pink: #E8DAE3;
$light-tan: #FAF7F2;

.btn.btn-primary,
a.btn.btn-primary, .navbar-light a.btn:visited {
  background: $app-navy;
  color: white;
  border-color: black;

  &:hover {
    background-color: #0f5d91;
    border-color: #0e5685;
  }
}

.bg-pink {
  background-color: $app-pink;
}

.bg-light-tan {
  background-color: $light-tan;
}
