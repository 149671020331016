@import url('https://fonts.googleapis.com/css2?family=Krub:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import "./_overrides";
@import "~bootstrap/scss/bootstrap";
@import '~@fortawesome/fontawesome-free';
@import "./_landing";
@import "./_login";
@import "./_legacies";
@import "./_payment";
@import "shared";

.photo-border {
  border: 2px solid #999;
  max-width: 80%;
}

textarea.form-control.h-64 {
  height: 16rem;
}
textarea.form-control.h-96 {
  height: 24rem;
}
textarea.form-control.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
textarea.form-control.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.border-black {
  border-color: #000000 !important;
}

/**
 * Creates a "breakpoint" based class, for example:
 *    $create-breakpoint-class('btn', 'purple')
 *      --> .btn-purple, .btn-sm-purple, .btn-md-purple, ...
 *    Where the class' content is applicable to that screen size.
 */
@mixin create-breakpoint-class($class-name-prefix, $class-name-suffix) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .#{$class-name-prefix}#{$infix}-#{$class-name-suffix} {
      @include media-breakpoint-up($breakpoint) {
        @content;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .page-container {
    min-height: 100%;
    position: relative;
  }

  .content-wrap {
    padding-bottom: 0;
  }

  .footer-public {
    position: relative;
    bottom: 0;
  }
}

@include create-breakpoint-class('w', 'auto') {
  width: auto !important;
}

.fixed-bottom-responsive {
  margin-top: 2em;
}

@include media-breakpoint-up(lg) {
  .fixed-bottom-responsive {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
}

.bg-light-blue {
  background: #F4FAFF;
}

.bg-tan {
  background: #ede5d8;
}

.navbar-brand {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
}

.navbar-collapse.collapse.show, .navbar-collapse.collapsing {
  .form-inline {
    display: block;

    .btn {
      width: 100%;
      margin-top: 1em;
      display: block;
    }
  }
}

.navbar.nav-secure {
  padding-bottom: 0px;

  .nav-item {
    padding-bottom: 0.5em;
  }

  .nav-item.active {
    border-bottom: 2px solid $primary;
    font-weight: bold;

    a {
      color: $primary;
    }
  }
}

body, html {
  font-family: Montserrat, Helvetica, sans-serif;
  font-weight: 400;
  color: $app-navy;
}

.navbar, .navbar-light {
  background: $app-pink;
  color: $light-tan;

  a, .navbar-nav a.nav-link {
    color: $light-tan;

    &:visited {
      color: $light-tan;
    }

    &:active {
      color: $light-tan;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'quiche-sans', Montserrat, Helvetica, sans-serif;
  color: rgb(39, 39, 42);
}

a,
.navbar a,
.nav-item .dropdown a,
.nav-link.dropdown-toggle {
  color: $app-navy;
  font-family: Montserrat, Helvetica, sans-serif;
  font-weight: 400;
}

a.dropdown-item {
  color: $app-navy !important;
}

.navbar-brand img {
  max-width: 165px;
}

.save-bar {
  background: $app-pink;
}

.footer-public {
  font-size: 22px;
  background-color: $app-pink;

  .links {
    color: $light-tan;

    &.text-muted {
      color: $light-tan;
    }

    a {
      display: block;
      color: $light-tan;
    }
  }
}

@include media-breakpoint-up(xl) {
  .homepage-banner {
    background-image: url('../images/woman-holding-heart-right.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto 90%;
  }
}

.logo-big {
  max-width: 300px;
  width: 100%;
}

h1.big {
  font-size: 60px;
  line-height: 1.3em;
}

.step-img {
  height: 300px;
  max-width: 100%;
}

.color-almost-black {
  color: #147BBF;
}

.font-size-18 {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
}

.font-size-22 {
  font-size: 22px;
  letter-spacing: 0;
  line-height: 27px;
}

.font-size-32 {
  font-size: 32px;
  letter-spacing: 0;
  line-height: 40px;
}

.font-size-23 {
  font-size: 23px;
  line-height: 40px;
  letter-spacing: -0.015em;
  color: rgba(0, 0, 0, 0.8);
}

.line-height-bigger {
  line-height: 1.3em;
}

.line-height-one-point-five {
  line-height: 1.5em;
}

.box-shadow {
  box-shadow: 0 0 4px 0 rgba(145, 145, 145, 0.5);
}

.btn-delete {
  border: none;
  margin-top: 4px;
}

.border.border-dashed {
  border-style: dashed !important;
  border-color: #BCBCBC;
}

.horizontal-line {
  overflow: hidden;
  text-align: center;

  &:before, &:after {
    background-color: #ccc;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &:before {
    right: 0.5em;
    margin-left: -50%;
  }

  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
}

label {
  color: #000000;
  font-size: 16px;
  font-weight: semi-bold;
  letter-spacing: 0;
  line-height: 20px;
}

.pill {
  padding: 1em 3em;
  border: 1px solid #FE8010;
  color: #FE8010;
  border-radius: 32.5px;
  position: relative;

  .dot {
    height: 20px;
    width: 20px;
    background: #FE8010;
    display: inline-block;
    position: absolute;
    left: 23px;
    top: 19px;
  }

  &.blue {
    border: 1px solid #147BBF;
    color: #147BBF;

    .dot {
      background: #147BBF;
    }
  }
}

.toast {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  max-width: 769px;
  height: 219px;
  width: 737px;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: calc(50% - (737px / 2));
  background-color: #E3EFF7;
  z-index: 999;
  font-size: 22px;
  color: #161616;
  line-height: 27px;

  .toast-header {
    display: block;
    background-color: #E3EFF7;
    border: 0;
    font-size: 120px;
    color: #1E7DBD;
  }
}

.border-gray, hr {
  border-color: #D3D3D3;
}

.social-icon {
  width: 109px;
  height: 109px;
  padding-top: 28px;
}

.button-link {
  background: transparent;
  border: 0;
  color: $primary;
}

.form-check-input.is-invalid ~ .form-check-label a {
  color: #dc3545;
}

// --------- SIGN UP PAGE ---------

.sign-up-container {
  background-color: #FFEEDD;

  .heading-container {
    margin: auto;
    max-width: 400px;

    h1 {
      font-size: 2.5rem;
      font-weight: normal;
      margin: 36px 0;
    }

    .logo {
      max-height: 85px;
    }

    .heart-graphic {
      display: block;
      margin: auto;
      max-height: 400px;
    }
  }
}


// --------- LEGACY PAGE ----------

body.legacies {
  .legacy-show {
    @include media-breakpoint-up(lg) {
      .legacy-content {
        margin-top: 0;
        padding: 3rem 10rem;
        //max-width: 800px;
      }
    }
  }

  .logo {
    img {
      width: 80%;
    }

    display: inline-block;
  }

  .call-to-action {
  }
}

.text-danger a {
  color: $danger;
}

.kiosk-card {
  .card-title {
    color: white;
    background: $app-navy;
    text-align: center;
    padding: 0.3em 0.2em 0.2em;
    min-height: 3em;

    img {
      max-width: 150px;
    }
  }

  img {
    width: 80%;
    max-width: 250px;
  }
}

.bg-floral-white {
  background: #faf7f0;
}

.bg-navy {
  background: $app-navy;
}

.palliative-logo img {
  width: 100%;
  max-width: 200px;
}

.doula-logo img {
  width: 100%;
  max-width: 158px;
}

.bg-doula-heart-hands {
  background-image: url(../images/doulas/heart-hands.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.border-bottom-pink {
  border-bottom: 5px solid $app-pink;
}

.doula-pink-pill {
  background: $app-pink;
  border-radius: 5px;
  text-align: center;
  padding: 1em;
  height: 100%;
}

.doula-card-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 227px;

  &.client-support {
    background-color: rgba(183, 138, 167, 0.75);;
    background-image: url(../images/doulas/hand-and-heart-image.png);
  }

  &.business-support {
    background-color: rgba(92, 127, 147, 0.7);;
    background-image: url(../images/doulas/tree-image.png);
  }

  h4 {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #002768;
  }
}

@include media-breakpoint-up(md) {
  .palliative-card-title {
    height: 8em;
  }
  .palliative-card-body {
    height: calc(100% - 8em);
  }
}

@include media-breakpoint-up(lg) {
  .palliative-card-title {
    height: 6em;
  }
  .palliative-card-body {
    height: calc(100% - 6em);
  }
}

.blue-divider {
  border: 1px solid $app-navy;
  width: 60px;
  margin-bottom: 1em;
}

.opacity-80 {
  opacity: 0.8;
}

trix-editor {
  &.customized-min-height {
    min-height: 15em;
  }
}

.letter-preview {
  .pink-bar {
    height: 6px;
    background: $app-pink;
  }
}

.tan-callout {
  font-family: Montserrat, Helvetica, sans-serif;
  letter-spacing: 0em;
}
