@import "shared";

.auth-sidebar {
  background-color: $app-light-pink;

  .logo {
    display: block;
    margin: auto;
    width: 75%;
  }

  h1 {
    font-size: 2rem;
    text-align: center;
  }

  .brand {
    color: #161616;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
  }

  .message {
    margin-top: 54px;
    color: #161616;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 49px;
  }
}

@include media-breakpoint-up(lg) {
  .auth-sidebar {
    .logo {
      width: 100%;
    }

    h1 {
      font-size: 3rem;
      text-align: left;
    }
  }
}
