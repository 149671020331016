$info: darken(#147BBF, 0%);
$primary: darken(#147BBF, 0%);
$font-family-sans-serif: "Montserrat", Arial, sans-serif;
$h1-font-size: 30px;

$input-padding-y: 0.5rem;
//$input-padding-x: 5px;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1680px
);
